#scrollBar {
    position: absolute;
    z-index: 110;

    right: 5px;
    top:50%;
    transform:translate(0%, -50%);
    
}
.sircleBar {
    width: 10px;
    height: 10px;
    margin: 10px 0;
    border-radius: 50px; 
    border: 3px solid transparent; 
    background: linear-gradient(90deg,rgb(219,1,0),rgb(247,143,0), rgb(0,186,0)) border-box; /*3*/
    -webkit-mask: 
        linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; 
    mask-composite: exclude;
}
.activeSircle {
    -webkit-mask: initial;
}
@keyframes slidein {
    from {
        bottom: 50px;
    }
  
    to {
        bottom: 20px;
    }
}
#scrollDown {
    pointer-events: none;
    
    width: 120px;
    height: 64px;

    border: 2px solid white;
    z-index: 110;
    opacity: 1;
    visibility: visible;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    left: 50%;
    bottom: 50px;
    transform:translate(-50%, 0%);
    
    border-radius: 20px;
    box-shadow: 5px 5px 10px black ;

    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out;

    transition: opacity 1s,
                visibility 0.5s linear;
}
#scrollDown > img {
    width: 60%;
    height: auto;
}


