* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: black; 
}
.invisibleDiv {
    opacity: 0!important;
    visibility: hidden!important;
}
#canvas_container {
    width: 100%;
    height: calc(100vh - 76px );
    transition: opacity 1s,
                visibility 0.5s linear;
}
#canvas_container > div {
    touch-action: none;
}
.cursorPoinder {
    cursor: url('../img/cursor/cursor1.png');
}
body > div > canvas {
    display: none!important;
}