.taste {
    top: 76px;
    width: 100vw;
    height: calc(100vh - 76px);
    
    pointer-events: none;

    position: absolute;
    z-index: 1;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Magistral';
   
    background-color: rgba(0,0,0, 0);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;

    transition: opacity 1s,
                visibility 0.5s linear;
}
.taste > div {
    padding: 0px 20px;
    padding-bottom: 20px;
}
.taste > .left {
    width: 32%;
    margin-left: 2vh;
    
}
.taste h6 {
    
    text-align: center;
    font-size: 5vh;
}
.red {
    color: rgb(219,1,0);
}
.yellow {
    color: rgb(247,143,0);
}
.green {
    color: rgb(0,186,0);
}
.taste > .left > p {
    margin-top: 13px;
    
    text-align: justify;
    font-size: 2vh;
}
.taste > .left > div {
    margin-top: 4vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.taste > .left > div > img{
    width: 24%;
    height: auto;
}
.taste > .right {
    width: 32%;
    margin-right: 2vh;
    text-align: center;
}
.taste > .right > p {
    font-size: 2.5vh;
}
.lowerH6 {
    margin-top: 5vh;
}
.taste > .right > .boxImg {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.taste > .right > .upper > img {
    width: 25%;
}
.lower > div {
    width: 20%;
}
.lower > div > img {
    width: 100%;
    height: auto;
}
.lower > div > p {
    font-size: 0.75vw;
    font-weight: 900;
    margin-top: 6px;
    margin-bottom: 12px;
}
.taste .centerSpin {
    position: absolute;
    width: 100px;
    height: auto;
    left: calc(50vw - 50px);
    top: 20px;
}
.crossTaste {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    height: auto;
    pointer-events: auto;
    cursor: pointer;
    
}
canvas {
    height: calc(100vh -76px);
}