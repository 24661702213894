.footerSection {
    position: absolute;
    width: 100%;
    height: calc(100vh - 76px);
    top: 76px;

    opacity: 1;
    visibility: visible;
    
    
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 150px;
    grid-auto-flow: column;
    
    transition: 
        opacity 1s,
        visibility 0.5s linear;
}
#contact {
    width: 100%;
    background-color: #fff;

    display: flex;
    grid-row: 1 / 2;
}
#contact > div{
    width: 50%;
}
#contact iframe {
    width: 100%;
    height: calc(100vh - 76px - 150px);
}


#formContact {
    text-align: center;
    align-self: center;
    font-family: 'Magistral';
}
input {
    border: 0px;
    border-bottom: 1px solid black;
    width: 90%;
    text-align: center;
    font-size: 2vh;
}
textarea {
    width: 90%;
}
#formContact h5{
    margin: 0%;
    font-size: 3vh;
}
#formContact p{
    margin: 0%;
    font-size: 2vh;
    margin: 5px;
    font-weight: 300;
}
#police {
    font-size: 1.9vh!important;
    margin-bottom: 0px!important;
}
label {
    font-size: 2.1vh;
    font-weight: 600;
}
form button {
    background-color: white;
    border: 2px solid black;
    font-size: 2vh;
    padding: 5px 10px;
    font-weight: 600;
    margin: 20px 0;
    cursor: pointer;
}


footer {
    grid-row: 2 / 3;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    align-items: center;
    justify-items: center;
    text-align: center;

    background-image: url('../img/background/footerBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
}
footer > div > p {
    color: #fff;
    font-family: 'Magistral';
    font-weight: 300;
}
#footLogo {
    width: 200px;
}
.contactFooter {
    display: flex;
    flex-wrap: wrap;

}
.contactFooter > img {
    height: 40px;
    width: 40px;
    margin-right: 12px;
}
#lastRight {
    margin-right: 0px;
    margin-left: 0px;
}