header > div#pc-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;

    background-image: url("../img/background/headerBackground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
header > div#pc-header > img {
    height: 56px;
    width: auto;
    margin: 10px 0px 10px 10px;
}
header > div#pc-header > div {
    display: flex;
}
nav {
    display: flex;
    align-items: center;
}
nav > div {
    font-family: 'Magistral';
    font-size: 13px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    
}
.imgLang {
    height: 16px;
    cursor: pointer;
    margin-right: 1.2vw;
    margin: 0px 20px 0px 20px;
}
#visLAng {
    height: 76px;
    display: flex;
    align-items: center;
    z-index: 100;
    position: relative;
    
    background-image: url("../img/background/headerBackground.png");
    background-size: 100vw 100%;
    background-repeat: no-repeat;
    background-position: right;
}
#hidenLang {
    position: absolute;
    
    z-index: 90;
    
    
}
#moveLang {
    top: -228px;
    transition: top 0.7s ease-in-out;
    position: relative;
    z-index: 99;
}
#hidenLang > div {
    height: 76px;
    display: flex;
    align-items: center;

    background-image: url("../img/background/headerBackground.png");
    background-size: 100vw 100%;
    background-repeat: no-repeat;
    background-position: right;
}
.closeMenu {
    top: 76px!important;
    
}
.reversBackground {
    transform: scale(1, -1);   
}
#visMobi {
    width: 100%;
    display: flex;

    justify-content: space-between;
    align-items: center;

    background-image: url("../img/background/headerBackground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 100;
    position: relative;

    
}
#visMobi > img {
    height: 56px;
    width: auto;
    margin: 10px 0 10px 0;
}
#visMobi > img.borderImg {
    height: 35px;
}
#visMobi > img.leftMenu {
    opacity: 0;
    margin-left: 10px;
}
#visMobi > img.rightMenu {
    margin-right: 10px;
    cursor: pointer;
}
#hidenMobi {
    width: 100%;
    position: absolute;
    z-index: 90;
    top: -312px;
    transition: top 0.7s ease-in-out;
}
#hidenMobi > div {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    background-image: url("../img/background/headerBackground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#hidenMobi > div > div {
    cursor: pointer;
    color: white;
    font-family: 'Magistral';
}
#hidenMobi > div > img {
    height: 25px;
    width: auto;
    cursor: pointer;
}
.rev {
    transform: scale(1, -1); 
}
.rev > div {
    transform: scale(1, -1); 
}
.rev > img {
    transform: scale(1, -1);
}
.openMobi {
    top: 76px!important;
}

body {
    overflow: hidden;
}
#mobi-header {
    display: none;
}

@media screen and (max-width: 800px) {
    #pc-header {
        display: none!important;
    }
    #mobi-header {
        display: block!important;
    }
}