/************LOADER***********/
#loader {
    position: absolute;
    background-color: white;
    z-index: 11;
    height: 100vh;
    width: 100vw;
    top: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 1;
    transition: opacity 0.5s, visibility 0.5s linear 0.5s;
    transition-delay: 1s;
    font-family: 'Magistral';
    z-index: 111;
    /*pointer-events: none;*/

}
#loader span {
    color: white;
    font-size: 0.8em
}
#loading {
    position: absolute;
    color: white;
}

.loader-animation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: visible;
  }
  .loader-animation .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .arrow.outer-1 {
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(219,0,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(219,0,0));
            filter: drop-shadow(0 0 18px rgb(219,0,0));
  }
  .arrow.outer-2 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(220,84,51);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(220,84,51));
            filter: drop-shadow(0 0 18px rgb(220,84,51));
  }
  .arrow.outer-3 {
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(219,0,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(219,0,0));
            filter: drop-shadow(0 0 18px rgb(219,0,0));
  }
  .arrow.outer-4 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(220,84,51);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(220,84,51));
            filter: drop-shadow(0 0 18px rgb(220,84,51));
  }
  .arrow.outer-5 {
    width: 0;
    height: 0;
    
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(220,84,51);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(220,84,51));
            filter: drop-shadow(0 0 18px rgb(220,84,51));
  }
  .arrow.outer-6 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(245,176,13);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(245,176,13));
            filter: drop-shadow(0 0 18px rgb(245,176,13));
  }
  .arrow.outer-7 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.outer-8 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.outer-9 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(42,184,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(42,184,0));
            filter: drop-shadow(0 0 18px rgb(42,184,0));
  }
  .arrow.outer-10 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(0,168,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(0,168,0));
            filter: drop-shadow(0 0 18px rgb(0,168,0));
  }
  .arrow.outer-11 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(42,184,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(42,184,0));
            filter: drop-shadow(0 0 18px rgb(42,184,0));
  }
  .arrow.outer-12 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(0,168,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(0,168,0));
            filter: drop-shadow(0 0 18px rgb(0,168,0));
  }
  .arrow.outer-13 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(42,184,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(42,184,0));
            filter: drop-shadow(0 0 18px rgb(42,184,0));
  }
  .arrow.outer-14 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(42,184,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(42,184,0));
            filter: drop-shadow(0 0 18px rgb(42,184,0));
  }
  .arrow.outer-15 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.outer-16 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.outer-17 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(245,176,13);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(245,176,13));
            filter: drop-shadow(0 0 18px rgb(245,176,13));
  }
  .arrow.outer-18 {
    width: 0;
    height: 0;
    /*margin-left: -24px;*/
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(220,84,51);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(220,84,51));
            filter: drop-shadow(0 0 18px rgb(220,84,51));
  }
  
  .arrow.inner-1 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(245,176,13);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(245,176,13));
            filter: drop-shadow(0 0 18px rgb(245,176,13));
  }
  .arrow.inner-2 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.inner-3 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.inner-4 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.inner-5 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(254,208,0);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(254,208,0));
            filter: drop-shadow(0 0 18px rgb(254,208,0));
  }
  .arrow.inner-6 {
    width: 0;
    height: 0;
    margin-left: -24px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 43.2px solid rgb(245,176,13);
    
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    -webkit-filter: drop-shadow(0 0 18px rgb(245,176,13));
            filter: drop-shadow(0 0 18px rgb(245,176,13));
  }
  .arrow.down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .arrow.outer-1 {
    -webkit-animation-delay: -0.05556s;
            animation-delay: -0.05556s;
  }
  .arrow.outer-2 {
    -webkit-animation-delay: -0.11111s;
            animation-delay: -0.11111s;
  }
  .arrow.outer-3 {
    -webkit-animation-delay: -0.16667s;
            animation-delay: -0.16667s;
  }
  .arrow.outer-4 {
    -webkit-animation-delay: -0.22222s;
            animation-delay: -0.22222s;
  }
  .arrow.outer-5 {
    -webkit-animation-delay: -0.27778s;
            animation-delay: -0.27778s;
  }
  .arrow.outer-6 {
    -webkit-animation-delay: -0.33333s;
            animation-delay: -0.33333s;
  }
  .arrow.outer-7 {
    -webkit-animation-delay: -0.38889s;
            animation-delay: -0.38889s;
  }
  .arrow.outer-8 {
    -webkit-animation-delay: -0.44444s;
            animation-delay: -0.44444s;
  }
  .arrow.outer-9 {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .arrow.outer-10 {
    -webkit-animation-delay: -0.55556s;
            animation-delay: -0.55556s;
  }
  .arrow.outer-11 {
    -webkit-animation-delay: -0.61111s;
            animation-delay: -0.61111s;
  }
  .arrow.outer-12 {
    -webkit-animation-delay: -0.66667s;
            animation-delay: -0.66667s;
  }
  .arrow.outer-13 {
    -webkit-animation-delay: -0.72222s;
            animation-delay: -0.72222s;
  }
  .arrow.outer-14 {
    -webkit-animation-delay: -0.77778s;
            animation-delay: -0.77778s;
  }
  .arrow.outer-15 {
    -webkit-animation-delay: -0.83333s;
            animation-delay: -0.83333s;
  }
  .arrow.outer-16 {
    -webkit-animation-delay: -0.88889s;
            animation-delay: -0.88889s;
  }
  .arrow.outer-17 {
    -webkit-animation-delay: -0.94444s;
            animation-delay: -0.94444s;
  }
  .arrow.outer-18 {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .arrow.inner-1 {
    -webkit-animation-delay: -0.16667s;
            animation-delay: -0.16667s;
  }
  .arrow.inner-2 {
    -webkit-animation-delay: -0.33333s;
            animation-delay: -0.33333s;
  }
  .arrow.inner-3 {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .arrow.inner-4 {
    -webkit-animation-delay: -0.66667s;
            animation-delay: -0.66667s;
  }
  .arrow.inner-5 {
    -webkit-animation-delay: -0.83333s;
            animation-delay: -0.83333s;
  }
  .arrow.inner-6 {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  
@-webkit-keyframes blink {
    0% {
      opacity: 0.1;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
}
  
@keyframes blink {
    0% {
      opacity: 0.1;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
}
