.container {
    top: 76px;
    opacity: 1;
    visibility: visible;
    pointer-events: none;

    font-family: 'Magistral';

    position: absolute;
    z-index: 10;
    
    /*flex-direction: column;*/
    display: flex;
    align-items: center;
    
    transition: opacity 1s, 
                visibility 0.5s linear 1s;
    transition-delay: 0s;
}
.container h1 {
    text-shadow: 0 1px 0 #ccc,
                 0 2px 0 #c9c9c9,
                 0 3px 0 #bbb,
                 0 4px 0 #b9b9b9,
                 0 5px 0 #aaa,
                 0 6px 1px rgba(0,0,0,.1),
                 0 0 5px rgba(0,0,0,.1),
                 0 1px 3px rgba(0,0,0,.3),
                 0 3px 5px rgba(0,0,0,.2),
                 0 5px 10px rgba(0,0,0,.25),
                 0 10px 10px rgba(0,0,0,.2),
                 0 20px 20px rgba(0,0,0,.15);
    text-align: center;
    font-size: 5em;
}
/***************************************/
#TextDescriptionFirst {
    width: 60%;
    left:40%;
    height: calc( 100% - 76px );
}
#TextDescriptionFirst div{
    align-self: center;
    padding: 50px;
    

}
#TextDescriptionFirst h1{
      margin-bottom: 2%;
    color: white;
    
}
#TextDescriptionFirst p{
    text-align: justify;
    font-size: 1.7em;
    margin: 0%;
    font-style: light;
    color: white;
    font-weight: 300;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    
}
/***********************************************************/
#TextDescriptionSecond1 {
    justify-items: center;
    width: 40%;
    height: calc( 100% - 76px );
}
#TextDescriptionSecond1 .one {
    padding: 50px;
    align-items: center;
    align-self: center;
}
#TextDescriptionSecond1 h1{
    color: white;
    margin-bottom: 2%;
}
#TextDescriptionSecond1 p {
    text-align: justify;
    font-size: 1.7em;
    margin: 0%;
    font-style: light;
    color: white;
    font-weight: 300;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}
/***************************************************/
#TextDescriptionSecond2 {
    justify-items: center;
    width: 40%;
    height: calc( 100% - 76px );
    left: 60%;
}
#TextDescriptionSecond2 .two {
    padding: 50px;
    align-items: start;
    align-self: center;
    left: 50%;
}
#TextDescriptionSecond2 h1{
      color: white;
    margin-bottom: 2%;
}
#TextDescriptionSecond2 p {
    text-align: justify;
    font-size: 1.7em;
    margin: 0%;
    font-style: light;
    color: white;
    font-weight: 300;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}
/************************************************/
#TextDescriptionThird {
    width: 60%;
    height: calc( 100% - 76px );
}
#TextDescriptionThird div{
    align-self: center;
    padding: 50px;
}
#TextDescriptionThird h1{
      margin-bottom: 2%;
    color: white;
}
#TextDescriptionThird p{
    text-align: justify;
    font-size: 1.7em;
    margin: 0%;
    color: white;
    font-weight: 300;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}
/************************************************************/
#TextDescriptionFourth {
    width: 100%;
    justify-content: center;
}
#TextDescriptionFourth h1{
      margin: 0%;
    color: white;
    

}
/***************************************/

#shop {
    display: flex;
    justify-content: center;
}

.PointerEvents {
    pointer-events: none;
}

.cursorPoinder {
    cursor: url('../img/cursor/cursor1.png'), pointer;
}
.autoPoint {
    pointer-events: auto;
}




#TextDescriptionFifth {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#TextDescriptionFifth h1{
    margin: 0%;
    color: white;
}
#TextDescriptionFifth h2 {
    text-align: center;
    margin: 0px;
    font-size: 2em;
    color: white;
}
.country {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.country > div {
    width: 192px;
    display: flex;
    align-items: center;
}
.country > div > img {
    width: 38px;
    height: auto;
}
.country > div > div {
    font-size: 1.7em;
    color: #fff;
    font-weight: 300;
    margin-left: 15px;
}
.heightZero {
    height: 0;
}
.heightZero > div {
    font-size: 0px!important;
}
.heightZero > img {
    height: 0!important;
}
.rot {
    transform: rotate(180deg);
}



.visHtml {
    
    /*transition-delay: 1.5s!important;*/
    opacity: 1!important;
    visibility: visible!important;
}

.hidHtml {
    
    /*transition-delay: 1.5s!important;*/
    opacity: 0!important;
    visibility: hidden!important;
}